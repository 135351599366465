<template>
  <v-data-iterator :items="merchantsStore.merchants" :page="page" :items-per-page="itemsPerPage" :search="search" filter-keys="legalEntityName">
    <template #header>
      <v-text-field v-model="search" label="Search" clearable />
    </template>
    <template #default="{ items }">
      <template v-for="(item, i) in items" :key="i">
        <v-card class="my-3 pa-5">
          <template #default>
            <div class="d-flex">
              <p class="flex-grow-1">
                <country-flag :country="item.raw.country" style="position: relative; top: 5px" />&nbsp;&nbsp;
                <span class="font-weight-bold">{{ item.raw.legalEntityName }}</span
                >&nbsp;
                <v-tooltip v-if="!merchantsStore.isMerchantOK(item.raw)" location="bottom">
                  <template #activator="{ props }"><v-icon color="warning" v-bind="props">mdi-alert</v-icon></template>
                  This company has not completed KYC. Click View to continue onboarding.
                </v-tooltip>
                <sub class="font-weight-thin font-italic">Created: {{ formatDate(item.raw.createdAt) }}</sub>
              </p>
              <div class="flex-grow-0">
                <v-dialog v-if="authorizationStore.isStaff" max-width="500">
                  <template #activator="{ props: activatorProps }">
                    <v-btn v-bind="activatorProps" :disabled="opsMerchantIDs.includes(item.raw.merchantId)" color="error" class="me-3 mb-1">
                      <template #prepend><v-icon v-if="smAndUp">mdi-swap-vertical</v-icon></template>
                      <span v-if="smAndUp">Transfer</span><v-icon v-else>mdi-swap-vertical</v-icon>
                    </v-btn>
                  </template>
                  <template #default="{ isActive }">
                    <v-card title="Transfer company">
                      <v-card-text>
                        <p>
                          You are about to transfer the company
                          <b
                            ><kbd>"{{ item.raw.legalEntityName }}"</kbd></b
                          >
                          - <kbd>{{ item.raw.merchantId }}</kbd> to another user.
                        </p>
                        <br />
                        <p>If no user exist with the given email, an invitation email will be sent to them.</p>
                        <br />
                        <v-text-field v-model="transferEmail" label="Email" :error-messages="validationErrors(v$.transferEmail.$errors)" type="search" />
                      </v-card-text>
                      <v-card-actions>
                        <v-btn-nd text="Cancel" @click="isActive.value = false" />
                        <v-spacer></v-spacer>
                        <v-btn
                          text="Transfer"
                          :loading="transferEmailLoading"
                          :disabled="v$.transferEmail.$invalid"
                          class="bg-primary on-primary"
                          @click="() => transfer(item.raw)"
                        />
                      </v-card-actions>
                    </v-card>
                  </template>
                </v-dialog>
                <v-btn
                  v-if="!item.raw.kycReviewOfDataDeadline"
                  :disabled="merchantsStore.currentMerchant?.merchantId === item.raw.merchantId"
                  class="mb-1"
                  @click="select(item.raw.merchantId)"
                >
                  <template #prepend><v-icon v-if="smAndUp">mdi-eye-outline</v-icon></template>
                  <span v-if="smAndUp">View</span><v-icon v-else>mdi-eye-outline</v-icon>
                </v-btn>
                <v-btn v-else color="error" class="me-3 mb-1" @click="select(item.raw.merchantId)">
                  <template #prepend><v-icon v-if="smAndUp">mdi-account-alert</v-icon></template>
                  <span v-if="smAndUp">Review now</span><v-icon v-else>mdi-account-alert</v-icon>
                </v-btn>
              </div>
            </div>
          </template>
        </v-card>
      </template>
    </template>
    <template #footer>
      <v-btn class="my-3 ms-5" @click="addCompanyModal = true">
        <template #prepend><v-icon>mdi-plus</v-icon></template>
        Add company
      </v-btn>
      <NewCompanyConfirmation v-model="addCompanyModal" />
      <v-pagination v-model="page" :length="totalPages" color="secondary" rounded="sm" @input="page = $event" />
    </template>
  </v-data-iterator>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from 'vue';
import { useDisplay } from 'vuetify';
import { ErrorObject, useVuelidate } from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { useApplicationStore } from '@/store/application';
import { useAuthorizationStore } from '@/store/authorization';
import { useMerchantsStore } from '@/store/merchants';
import { Merchant } from '@/api/merchant-service/merchant';

import NewCompanyConfirmation from '@/components/NewCompanyConfirmation.vue';

const appStore = useApplicationStore();
const authorizationStore = useAuthorizationStore();
const merchantsStore = useMerchantsStore();
const { smAndUp } = useDisplay();

const addCompanyModal = ref(false);

/**
 * IDs of merchants that are not allowed to be transfered, because used for operational purposes.
 */
const opsMerchantIDs = reactive([
  '7f50eae7-fe20-4ffe-b327-ec59668e4146', // [DEV] LoicPizzaReallyFinal
  '8581b5d8-df8e-4da0-a600-a98b90fae0ab', // [PROD] Meredith Papanicolaou
]);
const transferEmail = ref('');
const transferEmailLoading = ref(false);
const v$ = useVuelidate({ transferEmail: { required, email, $autoDirty: true } }, { transferEmail });

const itemsPerPage = ref(5);
const page = ref(1);
const totalPages = computed(() => Math.ceil(merchantsStore.merchants.length / itemsPerPage.value));
const search = ref('');

const select = (id: string) => {
  try {
    merchantsStore.switch(id);
    window.location.href = '/'; // Hard reload the app, simpler than trying to update all the relevant stores
  } catch (error) {
    console.error(error);
    appStore.notifyUser({ message: 'Failed to switch customer. Contact support.', type: 'error' });
  }
};

const transfer = async (merchant: Merchant) => {
  transferEmailLoading.value = true;
  try {
    await merchantsStore.transfer(merchant.merchantId, transferEmail.value.toLocaleLowerCase());
    window.location.href = '/companies';
  } catch (error) {
    console.error(error);
    appStore.notifyUser({ message: 'Failed to transfer company. Contact support.', type: 'error' });
  } finally {
    transferEmailLoading.value = false;
  }
};

// @ts-expect-error overload doesn't match
const formatDate = (isoDate: string) => new Date(isoDate).toLocaleDateString('en-UK', contentDateOptions);
const contentDateOptions = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
const validationErrors = (errors: ErrorObject[]) => errors.map((e) => e.$message.toString());
</script>
