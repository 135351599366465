<template>
  <div v-if="!thereWasAProblem">
    <SiteSelector v-model="queryParams.selectedSiteId" />
    <VueDatePicker
      v-model="queryParams.dateFilter"
      locale="en-UK"
      format="dd/MM/yyyy"
      placeholder="Filter by date(s)"
      :range="{ noDisabledRange: true }"
      :max-date="new Date()"
      :enable-time-picker="false"
      :preset-dates="datePickerPresetRanges"
    />
    <v-alert v-if="missingPermissions" type="warning" variant="outlined" density="compact" class="my-3">Please select a site to view payouts.</v-alert>
    <br />

    <v-skeleton-loader v-if="itemsLoading" class="mx-auto" type="list-item-two-line@5" />
    <div v-if="!itemsLoading && items.length === 0" class="text-center text-gray">No payouts for the selected search criterias</div>
    <div v-if="!itemsLoading && items.length > 0 && !missingPermissions">
      <v-expansion-panels v-model="activeItem" focusable>
        <v-expansion-panel v-for="(item, i) in items" :key="i">
          <v-expansion-panel-title>
            <div>
              {{ item.siteName }}<br />
              <span class="text-uppercase text-disabled caption">Payout date: {{ item.uxDate }}</span>
            </div>
            <div class="ms-auto">
              {{ item.uxAmount }}
              <br />
              <span class="text-uppercase text-disabled caption">Total paid out</span>
            </div>
          </v-expansion-panel-title>
          <v-expansion-panel-text>
            <DailySummary :days="item.payoutContent" :default-currency="item.currency" />
            <br />
            <v-container>
              <v-row no-gutters>
                <v-col>
                  <v-btn-nd :to="`/payouts/${item.yetiTransferId}`">Detail</v-btn-nd>
                </v-col>
                <v-col class="text-right">
                  <kbd>{{ item.yetiTransferId }}</kbd
                  ><br />
                  <span class="text-uppercase text--disabled caption">Payout reference</span>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-text>
        </v-expansion-panel>
      </v-expansion-panels>
      <br />
      <InfinitePagination v-model:current-page="currentPage" :last-key-pages="lastKeyPages" />
    </div>
  </div>

  <v-alert v-if="thereWasAProblem" color="red lighten-2" dark>
    Issue occurred while gathering your payouts. Please refresh or contact TableYeti support
  </v-alert>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, inject } from 'vue';
import { useRoute } from 'vue-router';
import _ from 'lodash';
import { endOfMonth, endOfWeek, startOfMonth, startOfWeek, startOfYear, subDays, subMonths, subWeeks } from 'date-fns';
import { API } from '@/plugins/api';
import InfinitePagination from '@/components/InfinitePagination.vue';

import DailySummary from '@/components/Payouts/DailySummary.vue';
import SiteSelector from '@/components/SiteSelector.vue';
import { Payout } from '@/api/merchant-service/payout';

const api = inject<API>('api');
const route = useRoute();

type EnhancedPayout = Payout & { currency: string; uxAmount: string; uxDate: string };

const datePickerPresetRanges = [
  { label: 'Today', value: [new Date(), new Date()] },
  { label: 'Yesterday', value: [subDays(new Date(), 1), subDays(new Date(), 1)] },
  { label: 'This week', value: [startOfWeek(new Date(), { weekStartsOn: 1 }), new Date()] },
  { label: 'Last week', value: [startOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 }), endOfWeek(subWeeks(new Date(), 1), { weekStartsOn: 1 })] },
  { label: 'This month', value: [startOfMonth(new Date()), new Date()] },
  { label: 'Last month', value: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))] },
  { label: 'Last 90 days', value: [subDays(new Date(), 90), new Date()] },
  { label: 'This year', value: [startOfYear(new Date()), new Date()] },
];

const currentPage = ref(1);
const items = ref<EnhancedPayout[]>([]);
const activeItem = ref<Payout | undefined>(undefined);
const itemsLoading = ref(false);
const lastKeyPages = ref<(string | undefined)[]>([undefined]);
const thereWasAProblem = ref(false);
const missingPermissions = ref(false);
const queryParams = ref<{ selectedSiteId?: string; dateFilter: Date[] }>({
  selectedSiteId: undefined,
  dateFilter: [],
});

const dateFilterQueryText = computed(() => {
  if (!queryParams.value.dateFilter) {
    return '';
  }
  let dates = [...queryParams.value.dateFilter].filter((d) => !!d).sort((a, b) => a.getTime() - b.getTime());
  return dates.map((d) => d.toISOString().split('T')[0]).join(',');
});
const internalAllQueryParams = computed(() => {
  return JSON.stringify(queryParams.value);
});

watch(currentPage, async (newPage) => await fetchData(newPage));
watch(internalAllQueryParams, async () => {
  const query = _.cloneDeep(route.query);

  if (queryParams.value.selectedSiteId && queryParams.value.selectedSiteId !== 'null') {
    query.siteId = queryParams.value.selectedSiteId;
  } else {
    queryParams.value.selectedSiteId = undefined;
    delete query.siteId;
  }

  if (queryParams.value.dateFilter && queryParams.value.dateFilter.length > 0) {
    query.date = dateFilterQueryText.value;
  } else {
    delete query.date;
  }

  const newQueryParams = Object.keys(query)
    .map((q) => q + '=' + query[q])
    .join('&');
  await fetchData(currentPage.value, true);
  history.pushState({}, '', route.path + (newQueryParams.length > 0 ? '?' + newQueryParams : ''));
});

onMounted(async () => {
  if (route.query.siteId) {
    queryParams.value.selectedSiteId = route.query.siteId as string;
  }
  if (route.query.date) {
    queryParams.value.dateFilter = (route.query.date as string).split(',').map((d) => new Date(d));
  }
  if (!queryParams.value.selectedSiteId && queryParams.value.dateFilter.length === 0) {
    await fetchData(currentPage.value);
  }
});

const fetchData = async (page: number, reset = false) => {
  if (reset) {
    currentPage.value = 1;
    lastKeyPages.value = [undefined];
    activeItem.value = undefined;
  }

  const lastKeyPagesIndex = page - 1;
  if (lastKeyPagesIndex >= lastKeyPages.value.length) {
    return;
  }

  itemsLoading.value = true;
  try {
    const result = await api!.payout.listPayouts(queryParams.value.selectedSiteId, dateFilterQueryText.value, lastKeyPages.value[lastKeyPagesIndex]);
    activeItem.value = undefined;
    items.value = result.items.map((item) => {
      const options = { year: '2-digit', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
      const enhancedItem: EnhancedPayout = {
        ...item,
        currency: item.amount.currency,
        uxAmount: item.amount.value.toLocaleString('en-UK', { style: 'currency', currency: item.amount.currency }),
        // @ts-expect-error overload doesn't match
        uxDate: new Date(item.date).toLocaleDateString('en-UK', options).replace(',', '').replaceAll('/', '-'),
      };
      return enhancedItem;
    });
    lastKeyPages.value[page] = result.lastKey; // this is for the next page, so the index is actually correct
    missingPermissions.value = false;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } catch (error: any) {
    console.error(error);
    if (error.response?.status === 403) {
      missingPermissions.value = true;
    } else {
      thereWasAProblem.value = true;
    }
  } finally {
    itemsLoading.value = false;
  }
};
</script>
