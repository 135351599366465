import { myAxios } from '@/plugins/api';
import { Amount } from './common-types';

export type TransactionType = 'Pay-by-link' | 'In-person';

export enum TransactionStatusV1 {
  Pending = 'pending',
  Captured = 'captured',
  Failed = 'failed',
  RefundPending = 'refund-pending',
  Refunded = 'refunded',
  PartiallyRefunded = 'partially-refunded',
  ChargebackPending = 'chargeback-pending',
  ChargedBack = 'chargedback',
}

export interface TransactionV1 {
  adyenMerchantAccountCode: string;
  adyenMerchantReference: string;
  customerAccountId: string;
  transactionId: string;
  siteId: string;
  siteName: string;
  siteReference: string;
  pspReference: string;
  paymentMethod: string;
  success: 'true' | 'false';
  successBool?: boolean;
  reason?: string;
  createdDate: string;
  amount: Amount;
  paymentLinkId?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  relatedPaymentNotifications: any[];
  status?: TransactionStatusV1;
  refunds?: TrxRefund[];
  chargebacks?: TrxChargeBack[];
  createdDatePspReference: string;
  siteCreatedDatePspReference: string;
  includedTipAmount?: Amount;
  enableRefund?: boolean; // Additional UI field
}

export interface TrxRefund {
  success?: boolean;
  pspReference: string;
  bookingDate: string;
  amount: Amount;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawData: any;
  reason?: string;
}
export interface TrxChargeBack {
  arn?: string;
  amount: Amount;
  reasonCode: string;
  schemeCode: string;
  defendable: boolean;
  defensePeriodEndsAt: string;
  disputeStatus: string;
  paymentMethodVariant: string;
  store: string;
  pspReference?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  rawData: any;
}

export interface TransactionReducedV1 {
  pspReference: string;
  paymentMethod: string;
  paymentLinkId: string;
  transactionId: string;
  adyenMerchantReference: string;
}

class TransactionApi {
  private readonly merchantId: () => string;
  constructor(merchantId: () => string) {
    this.merchantId = merchantId;
  }

  public async getTransactions(
    dates: string | undefined,
    siteId: string | undefined,
    psp: string | undefined,
    type: TransactionType | undefined,
    lastKey: string | undefined,
    countOnly = false,
  ): Promise<{ items: TransactionV1[]; lastKey: string; totalPages?: number }> {
    return myAxios
      .get(`/v2/merchants/${this.merchantId()}/transactions`, { params: { dates, siteId, psp, type, lastKey, countOnly } })
      .then((response) => response.data.data);
  }

  public async refundTransaction(pspReference: string, amount?: Amount): Promise<void> {
    return myAxios.post(`/merchants/${this.merchantId()}/transactions/${pspReference}/refunds`, { amount }).then((response) => response.data.data);
  }

  public async getTransactionsFromPsp(pspReferences: string[]): Promise<TransactionReducedV1[]> {
    return myAxios.post(`/v1/merchants/${this.merchantId()}/transactions/psp`, { pspReferences }).then((response) => response.data.data);
  }

  public async getTotals(siteId: string, startDate: Date): Promise<{ totalsCount: number; totalsAmount: Amount; tipsAmount: Amount }> {
    return myAxios
      .get(`/v1/merchants/${this.merchantId()}/transactions/totals`, {
        params: { siteId, startDate: startDate.toISOString() },
      })
      .then((response) => response.data);
  }
}

export default TransactionApi;
